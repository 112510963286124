import { Icons, Link, SvgSpriteConsumer } from '@pluxee-design-system/react';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { Employee } from 'generated/models';
import NextLink from 'next/link';

const DetailsCell = ({ row: { uid } }: ComponentProps<Employee>) => (
  <Link as={NextLink} href={`/employees/${uid}`} prefetch={false} variant="primary">
    <SvgSpriteConsumer size={32} svgId={Icons.THREE_DOTS_VERTICAL32} />
  </Link>
);

export default DetailsCell;
