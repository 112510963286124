// https://www.figma.com/file/mrdiByIw3khIMAh05RjAcL/002---PLX-UI-Library?type=design&node-id=51417-106094&mode=dev
import { Flex } from '@chakra-ui/react';
import { Background } from '@pluxee-design-system/react';
import { px, py } from 'common/layouts/layout.styles';
import { ReactNode } from 'react';

interface SplitScreenProps {
  children: ReactNode;
}

const SplitScreen = ({ children }: SplitScreenProps) => (
  <Flex flex="1 1 0%" align="stretch">
    <Flex direction="column" flex="1 1 50%" align="stretch" py={py} px={px}>
      {children}
    </Flex>
    <Flex hideBelow="lg" direction="column" flex="1 1 50%" align="stretch">
      <Background variant="chevron" width="100%" height="100%" />
    </Flex>
  </Flex>
);

export default SplitScreen;
