import { Link } from '@pluxee-design-system/react';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { Location } from 'generated/models';
import NextLink from 'next/link';

const MasterIdCell = ({ row: { masterId, uid } }: ComponentProps<Location>) => (
  <Link as={NextLink} href={`/locations/${uid}`} prefetch={false}>
    {masterId || <>&mdash;</>}
  </Link>
);

export default MasterIdCell;
