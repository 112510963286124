import { Link } from '@pluxee-design-system/react';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { Employee } from 'generated/models';
import NextLink from 'next/link';

const NameCell = ({ row: { firstName, middleName, lastName, uid } }: ComponentProps<Employee>) => (
  <Link as={NextLink} href={`/employees/${uid}`} prefetch={false}>
    {[firstName, middleName, lastName].filter((x) => x).join(' ')}
  </Link>
);

export default NameCell;
